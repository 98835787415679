import React from 'react';
import { Link, graphql } from 'gatsby';
import { Integration } from '../types/Integration';
import Content from '../layouts/main';
import styled from '../styled';
import { Typography, Tooltip, Button } from '@material-ui/core';
import { Check, ArrowRight } from 'react-feather';
import { SEO } from '../components/SEO';
import { ResponsiveImage } from '../components/ResponsiveImage';
import {
  SignupButton,
  SignupButtonWithChoice
} from '../components/SignupButton';
import {
  BlockContent,
  sanitySerializers
} from '../components/SanityBlockContent';
import {
  AffiliateProgram,
  AffiliateProgramList
} from '../types/AffiliateProgramList';
import { renderRate } from '../components/Spec';
import { max, maxBy, sortBy } from 'lodash-es';
import { COLORS } from '../themes/color';

type PageQueryData = {
  sanityAffiliateProgram: AffiliateProgram;
};

export const pageQuery = graphql`
  query($id: String) {
    sanityAffiliateProgram(id: { eq: $id }) {
      id
      screenshot {
        image {
          height
          src
          width
        }
        alt
        caption
      }
      name
      _rawDescription
      brands
      slug {
        current
      }
      niches {
        name
        slug {
          current
        }
        programs {
          screenshot {
            image {
              height
              src
              width
            }
            alt
            caption
          }
          name
          slug {
            current
          }
        }
      }
      alternatives {
        name
        connectionList {
          rates {
            currency
            description
            model
            range
            recurring
            type
          }
          network {
            name
            logo {
              image {
                src
              }
            }
          }
        }
      }
      connectionList {
        network {
          name
          status
          slug {
            current
          }
          logo {
            image {
              src
            }
          }
        }
        markets {
          name
          code
        }
        cookieDuration {
          number
          unit
        }
        rates {
          type
          recurring
          range
          model
          description
          currency
        }
        url
      }
    }
  }
`;

const SupportedNetworkBadge = styled('span')`
  position: absolute;
  right: 5%;
  bottom: 24px;
  background-color: #000;
  color: white;
  border-radius: 30px;
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
  align-items: center;
  font-size: 14px;
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
`;

const Hero = styled('div')``;

const HeroInner = styled('div')`
  text-align: center;
  align-items: center;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
`;

const ScreenshotWrapper = styled('div')`
  position: relative;
  padding: 130px ${(p) => p.theme.spacing(1)}px 0;

  img {
    width: 75%;
    transform: rotate(1.5deg);
    filter: drop-shadow(-5px 5px 30px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    border: 1px solid #ddd;
    display: inline-block;
    margin: 0 auto;
  }
`;

const COPY_FONT_SIZE = '18px';
const COPY_LINE_HEIGHT = '36px';

const MainWrapper = styled('div')`
  max-width: 900px;
  margin: ${(p) => p.theme.spacing(4)}px auto;
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  @media (max-width: 852px) {
    margin: ${(p) => p.theme.spacing(4)}px 12px;
  }

  p {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    a {
      border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
      color: ${(p) => p.theme.palette.primary.main};
      transition: 0.1s border-bottom linear;
      font-size: ${COPY_FONT_SIZE};
      line-height: ${COPY_LINE_HEIGHT};

      &:hover {
        border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
      }
    }
  }

  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
    transition: 0.1s border-bottom linear;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    &:hover {
      border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
    }
  }

  img {
    border: 1px solid #eee;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    max-width: 100%;
    height: auto;
    margin: ${(p) => p.theme.spacing(3)}px auto ${(p) => p.theme.spacing(3)}px;
  }
`;

const NetworkTableWrapper = styled('table')`
  margin: ${(p) => p.theme.spacing(4)}px auto;
  padding: ${(p) => p.theme.spacing(2)}px 0;
  width: 100%;
  max-width: 900px;
  overflow-x: auto;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  tr {
    vertical-align: middle;
  }

  th {
    color: ${(p) => p.theme.palette.grey[500]};
    text-transform: uppercase;
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    padding: ${(p) => p.theme.spacing(1)}px;
  }

  td {
    padding: ${(p) => p.theme.spacing(1)}px;
    vertical-align: center;
  }

  td:last-child,
  th:last-child {
    text-align: right;
  }

  @media (max-width: 700px) {
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
    }

    td:last-child,
    th:last-child {
      text-align: left;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      /* Label the data */
      content: attr(data-column);
      font-weight: bold;
      color: ${(p) => p.theme.palette.grey[500]};
      text-transform: uppercase;
      font-size: 14px;
      line-height: 28px;
      text-align: left;
    }
  }
`;

const IntegratedCheck = styled('span')`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
`;

const NetworkLogoWrapper = styled('td')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: ${(p) => p.theme.spacing(1)}px;
  line-height: 28px;

  a {
    display: inline-block;
  }

  img {
    text-align: left;
    width: 100%;
  }
`;

const NetworkLogoRow = ({ network }: { network: Integration }) => {
  if (network.logo) {
    return (
      <NetworkLogoWrapper data-column="Network">
        {network.slug ? (
          <Link to={`/integrations/${network.slug.current}/`}>
            <img
              alt={`${network.name}`}
              src={network.logo.image.src}
              title={`${network.name}`}
              style={{
                display: 'inline-block',
                maxHeight: '30px',
                maxWidth: '250px'
              }}
            />
          </Link>
        ) : (
          <img
            alt={`${network.name}`}
            src={network.logo.image.src}
            title={`${network.name}`}
            style={{
              display: 'inline-block',
              maxHeight: '30px',
              maxWidth: '250px'
            }}
          />
        )}
        {network.status === 'supported' && (
          <Tooltip title="Data integrates with Affilimate" placement="top">
            <IntegratedCheck style={{ position: 'relative', top: '-5px' }}>
              <Check size={14} />
            </IntegratedCheck>
          </Tooltip>
        )}
      </NetworkLogoWrapper>
    );
  }

  return <td>{network.name}</td>;
};

const NetworkTable = ({
  connectionList
}: {
  connectionList: AffiliateProgram['connectionList'];
}) => {
  const list = sortBy(connectionList, (l) => l.network.status !== 'supported');

  return (
    <NetworkTableWrapper>
      <thead>
        <tr>
          <th style={{ width: '30%' }}>Network</th>
          <th style={{ width: '22%' }}>Commission rate</th>
          <th style={{ width: '12%' }}>Cookie</th>
          <th style={{ width: '15%' }}>Markets</th>
          <th style={{ width: '20%' }}>Details</th>
        </tr>
      </thead>
      <tbody>
        {list.map((c) => {
          let cookieDuration = '';
          if (!c.cookieDuration) {
            cookieDuration = 'Not listed';
          } else if (c.cookieDuration.unit === 'forever') {
            cookieDuration = 'Forever';
          } else if (c.cookieDuration.number === null) {
            cookieDuration = 'Not listed';
          } else {
            const { number: n, unit } = c.cookieDuration;
            cookieDuration = `${n} ${unit}`;
          }

          return (
            <tr>
              <NetworkLogoRow network={c.network} />
              <td data-column="Rates">
                {c.rates.length
                  ? c.rates.map(renderRate).join(', ')
                  : 'Not listed'}
              </td>
              <td data-column="Cookie">{cookieDuration}</td>
              <td>
                {c.markets.length
                  ? c.markets.map((m) => m.name).join(', ')
                  : 'Global'}
              </td>
              <td data-column="Details">
                <a
                  href={c.url}
                  target="_blank"
                  style={{ borderBottom: '1px dashed' }}
                >
                  Join program <ArrowRight size={14} />
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </NetworkTableWrapper>
  );
};

const IntegratesLegend = styled('p')`
  font-weight: bold;
  color: ${(p) => p.theme.palette.primary.main};
  display: flex;
  align-items: center;
  gap: 6px;
`;

const BannerOuter = styled('div')`
  background-color: ${COLORS.PAPER_DARK};
  color: #333;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 8px;

  img {
    position: absolute;
    top: 14%;
    right: 0;
    width: 40%;
  }

  @media (max-width: 600px) {
    margin: 0 12px;

    img {
      width: 90%;
      top: 10px;
    }
  }
`;

const BannerContent = styled('div')`
  padding: 75px;
  width: 60%;

  @media (max-width: 600px) {
    padding: 300px 12px 12px 12px;
    width: 100%;
  }
`;

const SubCTA = styled('p')`
  display: flex;
  color: #555;
`;

const Strong = styled('strong')`
  color: #000;
  box-shadow: inset 0 -12px 0 #ddd3bd29;
`;

const AffilimateBanner = ({ name }: { name?: string }) => {
  return (
    <BannerOuter>
      <BannerContent>
        <Typography
          variant="h4"
          component="h2"
          style={{ fontWeight: 'bold', marginBottom: '24px' }}
        >
          Already earning commissions{name && ` from ${name}`}? Track your
          affiliate earnings from all networks in one dashboard.
        </Typography>
        <Typography
          variant="body1"
          component="p"
          style={{ marginBottom: '36px' }}
        >
          Affilimate is an analytics tool that aggregates{' '}
          <Strong>all your website's affiliate data</Strong> in a single
          dashboard.
          <br />
          <br />
          Then, gives you{' '}
          <Strong>attribution data &amp; content insights</Strong> that reveals
          which pages and links are earning the most, so you can grow your
          revenue.
        </Typography>
        <Link to="/">
          <Button variant="contained" size="large" color="primary">
            <strong>Learn more about Affilimate</strong>
          </Button>
        </Link>
        <SubCTA>
          Affilimate is a paid analytics product, not an affiliate network.
        </SubCTA>
      </BannerContent>
      <img src="/images/affilimate-hero.png" alt="Affilimate" />
    </BannerOuter>
  );
};

const NichesGrapWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  max-width: 900px;
  margin: ${(p) => p.theme.spacing(4)}px auto ${(p) => p.theme.spacing(8)}px;

  a {
    display: block;
    position: relative;
    transition: all 0.2s ease-in-out;
    transform: scale(1);

    &:hover {
      transform: scale(1.05);
    }
  }

  .inner {
    position: relative;
    margin: 0 auto;
  }

  span {
    display: inline-block;
    position: absolute;
    bottom: 12px;
    right: -6px;
    border-radius: 4px;
    background-color: #000;
    color: white;
    padding: 6px 16px;
    border: 1px solid #ddd;
    font-size: 14px;

    svg {
      position: relative;
      top: 1px;
    }
  }

  img {
    border-radius: 8px;
    height: 160px;
    max-width: 100%;
    border: 1px solid #ddd;
    filter: drop-shadow(-5px 5px 30px rgba(0, 0, 0, 0.1));
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    padding: 12px;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    justify-content: center;
    padding: 12px;
  }
`;

const NichesGrid = ({ list }: { list: AffiliateProgramList }) => {
  return (
    <NichesGrapWrapper>
      {list.programs.map((p) => (
        <div className="inner">
          <Link key={p.slug.current} to={`/programs/${p.slug.current}/`}>
            <ResponsiveImage
              src={p.screenshot.image.src}
              alt={p.screenshot.alt}
            />
            <span>
              {p.name} <ArrowRight size={12} />
            </span>
          </Link>
        </div>
      ))}
    </NichesGrapWrapper>
  );
};

const getHighestCommissions = (
  connections: AffiliateProgram['connectionList']
) => {
  // First check if any flat commissions over 10 units

  // Then check for highest percentage
  const maxPerConnection = connections.map((c) => {
    const maxRate = maxBy(c.rates, (r) => {
      const highest = max(r.range);
      return highest;
    });
    return { rate: maxRate, network: c.network };
  });

  const highestRate = maxBy(maxPerConnection, (c) => {
    return max(c.rate?.range);
  });

  if (!highestRate || !highestRate.rate) {
    return null;
  }

  return ` with commissions up to ${renderRate(highestRate.rate).trim()} on ${
    highestRate.network.name
  }.`;
};

const IntroParagraph = ({ program }: { program: AffiliateProgram }) => {
  if (program.niches.length === 0) {
    return null;
  }

  const joinedList = (list: AffiliateProgram['niches']) => {
    const linkedNichesList = list.map((niche, i) => (
      <>
        <Link
          to={`/blog/${niche.slug.current}/`}
          key={niche.name}
          style={{ borderBottom: '1px solid' }}
        >
          {niche.name}
        </Link>
        {i !== list.length - 1 && list.length > 2 && ', '}
        {i === list.length - 2 && list.length > 1 && ' and '}
      </>
    ));
    if (linkedNichesList.length === 1) {
      return <>{linkedNichesList[0]} niche</>;
    }
    return <>{linkedNichesList} niches</>;
  };

  const highestCommissionText = getHighestCommissions(program.connectionList);

  return (
    <Typography variant="body1" component="p" paragraph>
      {program.name} has an affiliate program in the{' '}
      {joinedList(program.niches)}
      {highestCommissionText || `.`} Learn how to join the {program.name}{' '}
      affiliate program, where to get the best rates, and the longest cookie
      period through our affiliate program guide below.
    </Typography>
  );
};

export default function Template({ data }: { data: PageQueryData }) {
  const { sanityAffiliateProgram: program } = data;
  const assignedToNiche = program.niches.length > 0;

  const showIntegratesLegend =
    program.connectionList.filter((c) => c.network.status === 'supported')
      .length > 0;

  return (
    <Content>
      <SEO
        title={`${program.name} Affiliate Program: Find the Best Rates in 2024`}
        description={`Find out where to join the ${program.name} affiliate program, and compare rates and cookie periods across affiliate networks.`}
        pathname={`/programs/${program.slug.current}/`}
        socialSharingImage={program.screenshot.image.src}
      />
      <Hero>
        <HeroInner>
          <ScreenshotWrapper>
            <ResponsiveImage
              src={program.screenshot.image.src}
              alt={program.screenshot.alt}
            />
            {showIntegratesLegend && (
              <SupportedNetworkBadge>
                <Check size={18} /> Data integrates with Affilimate
              </SupportedNetworkBadge>
            )}
          </ScreenshotWrapper>
          <div style={{ maxWidth: '80%', margin: '0 auto' }}>
            <h1>Join the {program.name} Affiliate Program</h1>
            <IntroParagraph program={program} />
          </div>
        </HeroInner>
      </Hero>
      <NetworkTable connectionList={program.connectionList} />
      <MainWrapper>
        {showIntegratesLegend && (
          <IntegratesLegend>
            <IntegratedCheck>
              <Check size={14} />
            </IntegratedCheck>
            <span>Data integrates with Affilimate</span>
            <svg
              width="25"
              height="17"
              viewBox="0 0 25 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3477 16.758C17.7664 17.1182 18.3978 17.0709 18.758 16.6523L24.6283 9.83026C24.9885 9.41163 24.9412 8.78024 24.5226 8.42C24.1039 8.05977 23.4725 8.10712 23.1123 8.52575L17.8943 14.5897L11.8303 9.3717C11.4116 9.01147 10.7802 9.05882 10.42 9.47745C10.0598 9.89608 10.1071 10.5275 10.5257 10.8877L17.3477 16.758ZM1.51696 3.81245C3.9467 2.34509 7.51151 1.31275 10.534 2.55587C13.4631 3.76062 16.3458 7.31269 17.0028 16.0748L18.9972 15.9252C18.3165 6.84708 15.2538 2.33459 11.2947 0.706212C7.42893 -0.883778 3.16259 0.482208 0.483043 2.10043L1.51696 3.81245Z"
                fill="#828282"
              />
            </svg>
          </IntegratesLegend>
        )}
      </MainWrapper>
      <AffilimateBanner
        name={showIntegratesLegend ? program.name : undefined}
      />
      <MainWrapper>
        <Typography variant="h5" component="h2" style={{ fontWeight: 'bold' }}>
          What you should know about the {program.name} affiliate program
        </Typography>
        <BlockContent
          blocks={program._rawDescription}
          serializers={sanitySerializers}
        />
      </MainWrapper>
      {assignedToNiche && (
        <>
          {program.niches.map((niche) => (
            <>
              <MainWrapper>
                <Link to={`/blog/${niche.slug.current}/`}>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ fontWeight: 'bold' }}
                  >
                    Explore more {niche.name} affiliate programs{' '}
                    <ArrowRight
                      size={24}
                      style={{ position: 'relative', top: '4px' }}
                    />
                  </Typography>
                </Link>
              </MainWrapper>
              <NichesGrid list={niche} />
            </>
          ))}
        </>
      )}
    </Content>
  );
}
